<template>
  <div class="page contact" :loaded="Boolean(locations && settings)">
    <ContactForm :ways="settings ? settings.ways : []" />

    <section ref="offices" class="offices" v-if="locations">
      <div class="wrapper">
        <h3>Our Offices</h3>
        <!-- <h5>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</h5> -->
        <div class="container">
          <div class="office" v-for="location in locations" :key="location.id">
            <img :src="location.image" alt="" class="office-image">
            <div class="office-info">
              <div class="office-name">
                {{location.name}}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80.408 90.921" :style="{ color: location.color }">
                  <g id="Blob_Set_03" transform="translate(80.408 90.91) rotate(180)">
                    <g id="V03" transform="translate(0 -0.012)">
                      <g id="Group_640" data-name="Group 640" transform="translate(0 0)">
                        <path id="Path_481" data-name="Path 481" fill="currentColor" d="M695.791,496.08a2.978,2.978,0,0,0-.336-1.183,3.557,3.557,0,0,0-.651-1.494,3.387,3.387,0,0,0-.408-.734,2.281,2.281,0,0,0-2.373-1.006l-.057.014a3.16,3.16,0,0,0-.9-.052,3.517,3.517,0,0,0-3.044,2.926,3.645,3.645,0,0,0-.047.518,4.146,4.146,0,0,0,.227,2.722,3.166,3.166,0,0,0,3.466,1.545,3.05,3.05,0,0,0,1.379.06A3.59,3.59,0,0,0,695.791,496.08Z" transform="translate(-616.6 -440.685)"/>
                        <path id="Path_482" data-name="Path 482" fill="currentColor" d="M701.025,382.323a3.043,3.043,0,0,0-.528-.059c-2.042-.042-3.588,1.864-3.905,3.744a3.551,3.551,0,0,0,.725,3.042,3.8,3.8,0,0,0,2.578,1.139,4.6,4.6,0,0,0,2.59-.547,2.947,2.947,0,0,0,1.091-1.126,4.215,4.215,0,0,0,1.061-1.682A3.624,3.624,0,0,0,701.025,382.323Z" transform="translate(-624.375 -342.667)"/>
                        <path id="Path_483" data-name="Path 483" fill="currentColor" d="M685.1,272.319a3.936,3.936,0,0,0-2.226-1.29,3.212,3.212,0,0,0-1.235-.3,3.462,3.462,0,0,0-3.143,2.322,4.033,4.033,0,0,0-.351,3.647c.855,2.043,3.052,2.486,4.955,1.689.06-.025.12-.051.179-.078a5.1,5.1,0,0,0,1.424-.569,3.123,3.123,0,0,0,1.59-2.6A4.256,4.256,0,0,0,685.1,272.319Z" transform="translate(-607.647 -242.685)"/>
                        <path id="Path_484" data-name="Path 484" fill="currentColor" d="M658.26,599.867a3.246,3.246,0,0,0-1.328-1.2,3.346,3.346,0,0,0-1.381-.843,3.523,3.523,0,0,0-4.094,2.405,3.635,3.635,0,0,0-.259,1.537,5.265,5.265,0,0,0,.85,2.905,1.3,1.3,0,0,0,.094.143c.009.017.018.034.028.051a1.69,1.69,0,0,0,1.338.834,1.278,1.278,0,0,0,.235-.012c.121.067.257.153.276.159a1.952,1.952,0,0,0,1.56-.3,3.405,3.405,0,0,0,2.3-1.259A3.94,3.94,0,0,0,658.26,599.867Z" transform="translate(-583.74 -535.788)"/>
                        <path id="Path_485" data-name="Path 485" fill="currentColor" d="M596.874,689.088a2.978,2.978,0,0,0-4.224.043,4.246,4.246,0,0,0-.684,3.977,1.418,1.418,0,0,0,.382.652c.04.094.082.186.125.274a2.545,2.545,0,0,0,3.339,1.388,4.117,4.117,0,0,0,2.279-2.456A3.957,3.957,0,0,0,596.874,689.088Z" transform="translate(-530.473 -616.942)"/>
                        <path id="Path_486" data-name="Path 486" fill="currentColor" d="M512.4,770.376a2.175,2.175,0,0,0-1.339,1.516,2.449,2.449,0,0,0-.283,1.386,2.717,2.717,0,0,0,1.4,2.227,3.153,3.153,0,0,0,2.9-.207,2.793,2.793,0,0,0,1.463-2.279C516.738,770.85,514.213,769.635,512.4,770.376Z" transform="translate(-457.864 -690.383)"/>
                        <path id="Path_487" data-name="Path 487" fill="currentColor" d="M417.4,813.939a2.994,2.994,0,0,0-.725-1.841,1.948,1.948,0,0,0-2.575-.214,2.3,2.3,0,0,0-.725.977,1.555,1.555,0,0,0-.273.377,2.284,2.284,0,0,0,2.091,3.156A2.166,2.166,0,0,0,417.4,813.939Z" transform="translate(-370.115 -727.439)"/>
                        <path id="Path_488" data-name="Path 488" fill="currentColor" d="M309.175,831.32a2.828,2.828,0,0,0-2.237-.145,2.079,2.079,0,0,0-1.045.311c-2.091,1.3-.142,4.636,1.956,4.35a3.313,3.313,0,0,0,2.312-1.57A2.208,2.208,0,0,0,309.175,831.32Z" transform="translate(-273.404 -744.932)"/>
                        <path id="Path_489" data-name="Path 489" fill="currentColor" d="M209.186,832.44c-.006-.023-.013-.046-.021-.068a1.5,1.5,0,0,0-.386-.663,1.673,1.673,0,0,0-1.062-.439,1.5,1.5,0,0,0-.758.207l-.088.067a1.45,1.45,0,0,0-.717,1.5,1.111,1.111,0,0,0,.178.558l.234.3a1.505,1.505,0,0,0,.663.386l.4.054h.082a1.141,1.141,0,0,0,.427-.051l.358-.151a1.508,1.508,0,0,0,.539-.539c.051-.12.1-.239.151-.358A1.494,1.494,0,0,0,209.186,832.44Z" transform="translate(-184.783 -745.165)"/>
                        <path id="Path_490" data-name="Path 490" fill="currentColor" d="M115.752,797.043a.837.837,0,0,0-.236-.367.847.847,0,0,0-.367-.236.862.862,0,0,0-.438-.1l-.3.041a1.13,1.13,0,0,0-.5.293l-.178.23a1.125,1.125,0,0,0-.155.575v.11a.854.854,0,0,0,.1.439.869.869,0,0,0,.236.367.844.844,0,0,0,.367.235.853.853,0,0,0,.439.1l.3-.04a1.148,1.148,0,0,0,.5-.293c.06-.077.119-.154.178-.231a1.13,1.13,0,0,0,.156-.575v-.11A.862.862,0,0,0,115.752,797.043Z" transform="translate(-101.807 -713.849)"/>
                        <path id="Path_491" data-name="Path 491" fill="currentColor" d="M28.326,737.406a.913.913,0,0,0-.587-.242.834.834,0,0,0-.585.242.86.86,0,0,0-.243.586v.109a.857.857,0,0,0,.243.586.614.614,0,0,0,.266.171.622.622,0,0,0,.319.072.837.837,0,0,0,.586-.242.861.861,0,0,0,.242-.586v-.109A.853.853,0,0,0,28.326,737.406Z" transform="translate(-24.123 -660.805)"/>
                        <path id="Path_492" data-name="Path 492" fill="currentColor" d="M157.055,693.657a.359.359,0,0,0-.093-.161.55.55,0,0,0-.207-.186.869.869,0,0,0-.367-.1.549.549,0,0,0-.279.063,1.192,1.192,0,0,0-.278.156.746.746,0,0,0-.26.762.735.735,0,0,0,.333.433l.174.074a.727.727,0,0,0,.257.021.669.669,0,0,0,.414-.094l.147-.113a.725.725,0,0,0,.186-.32.614.614,0,0,1,.008-.063c.009-.065.018-.13.026-.194A.538.538,0,0,0,157.055,693.657Z" transform="translate(-139.438 -621.401)"/>
                        <path id="Path_493" data-name="Path 493" fill="currentColor" d="M251.679,721.876a.983.983,0,0,0-.268-.414.971.971,0,0,0-.414-.267.985.985,0,0,0-.5-.112l-.344.047a1.289,1.289,0,0,0-.572.333l-.2.262a1.294,1.294,0,0,0-.177.654v.11a.981.981,0,0,0,.112.5,1.157,1.157,0,0,0,.685.685.981.981,0,0,0,.5.112l.345-.047a1.289,1.289,0,0,0,.571-.332c.067-.088.135-.175.207-.262a1.285,1.285,0,0,0,.176-.654v-.11A.971.971,0,0,0,251.679,721.876Z" transform="translate(-223.389 -646.39)"/>
                        <path id="Path_494" data-name="Path 494" fill="currentColor" d="M343.4,706.643a2.1,2.1,0,0,0-1.54,1.563,2.317,2.317,0,0,0,.1,1.78c.8,1.616,3.355.94,3.791-.608A2.127,2.127,0,0,0,343.4,706.643Z" transform="translate(-306.333 -633.403)"/>
                        <path id="Path_495" data-name="Path 495" fill="currentColor" d="M434.382,664.652a2.974,2.974,0,0,0-1.783,1.223,2.9,2.9,0,0,0-.12,2.553,2.155,2.155,0,0,0,2.2,1.45,3.49,3.49,0,0,0,2.476-1.162C438.534,667.006,436.326,664.372,434.382,664.652Z" transform="translate(-387.469 -595.787)"/>
                        <path id="Path_496" data-name="Path 496" fill="currentColor" d="M506.54,586.935c-.48-1.761-2.166-3.736-4.205-3.186a4.812,4.812,0,0,0-2.359,2.046c-.715,1.036-.279,2.257.219,3.286a3.288,3.288,0,0,0,1.53,1.635l.023.011.092.039a3.4,3.4,0,0,0,1.554.228,4.078,4.078,0,0,0,1.937-.414,1.188,1.188,0,0,0,.582-.676c.038-.04.077-.083.113-.126A3.041,3.041,0,0,0,506.54,586.935Z" transform="translate(-447.85 -523.198)"/>
                        <path id="Path_497" data-name="Path 497" fill="currentColor" d="M555.964,499.97a4.362,4.362,0,0,0-3.4-2.608,2.882,2.882,0,0,0-2.554.607,3.392,3.392,0,0,0-.871,1.951,5.007,5.007,0,0,0,.054,2.327,3.25,3.25,0,0,0,1.735,1.838,2.5,2.5,0,0,0,1.2.422,4.288,4.288,0,0,0,3.174-1.036A3.234,3.234,0,0,0,555.964,499.97Z" transform="translate(-492.157 -445.785)"/>
                        <path id="Path_498" data-name="Path 498" fill="currentColor" d="M567.655,392.655a3.3,3.3,0,0,0-.414-4.04A4,4,0,0,0,564.4,387.4a3.228,3.228,0,0,0-2.634,1.731,3.924,3.924,0,0,0-.49,2.822,2.811,2.811,0,0,0,.467,1.48,3.3,3.3,0,0,0,4.493.725A2.359,2.359,0,0,0,567.655,392.655Z" transform="translate(-503.068 -347.273)"/>
                        <path id="Path_499" data-name="Path 499" fill="currentColor" d="M547.506,290.5c-1.385-1.809-4.638-.829-5.537,1.026a3.093,3.093,0,0,0,.017,2.818,2.447,2.447,0,0,0,.323,1.117,2.062,2.062,0,0,0,1.117.918,3.166,3.166,0,0,0,1.061.6,1.07,1.07,0,0,0,.475.094c.055,0,.112.007.168.007,1.406,0,3.12-1.235,2.92-2.693A4.347,4.347,0,0,0,547.506,290.5Z" transform="translate(-485.534 -259.548)"/>
                        <path id="Path_500" data-name="Path 500" fill="currentColor" d="M489.568,211.421a3.349,3.349,0,0,0-4.192.957,3.176,3.176,0,0,0-.453,2.55,2.659,2.659,0,0,0,1.6,1.781,1.217,1.217,0,0,0,1.078.083,4.051,4.051,0,0,0,3.171-1.668A2.481,2.481,0,0,0,489.568,211.421Z" transform="translate(-434.61 -189.207)"/>
                        <path id="Path_501" data-name="Path 501" fill="currentColor" d="M416.426,152.328a2.415,2.415,0,0,0-2.228,1.15,3.7,3.7,0,0,0-.5,2.345,1.347,1.347,0,0,0,.81,1.2,2.224,2.224,0,0,0,.265.194,2.723,2.723,0,0,0,3.819-1.473A2.5,2.5,0,0,0,416.426,152.328Z" transform="translate(-370.842 -136.54)"/>
                        <path id="Path_502" data-name="Path 502" fill="currentColor" d="M313.853,125.967a1.788,1.788,0,0,0-2.1-.989,3.67,3.67,0,0,0-1.09.53,3.25,3.25,0,0,0-.518.172,1.32,1.32,0,0,0-.6,1.463,2.268,2.268,0,0,0,.715,1.347,1.6,1.6,0,0,0,.453.373,1.711,1.711,0,0,0,.776.195,1.917,1.917,0,0,0,.37,0h.031a1.39,1.39,0,0,0,.809-.3A2.459,2.459,0,0,0,313.853,125.967Z" transform="translate(-277.469 -111.977)"/>
                        <path id="Path_503" data-name="Path 503" fill="currentColor" d="M225.164,135a1.45,1.45,0,0,0-.7-1.208,1.647,1.647,0,0,0-.848-.228,1.533,1.533,0,0,0-1.094.443,1.614,1.614,0,0,0-.376.641,1.467,1.467,0,0,0-.034.212,1.877,1.877,0,0,0-.027.215,1.613,1.613,0,0,0,.253.873.977.977,0,0,0,.244.3,1.075,1.075,0,0,0,.329.277,1.274,1.274,0,0,0,1,.131,1.94,1.94,0,0,0,.725-.427,1.6,1.6,0,0,0,.321-.42,2.022,2.022,0,0,0,.158-.385,1.921,1.921,0,0,0,.051-.377A.227.227,0,0,1,225.164,135Z" transform="translate(-199.086 -119.733)"/>
                        <path id="Path_504" data-name="Path 504" fill="currentColor" d="M177.093,24.262a1.783,1.783,0,0,0-3.049.979,1.475,1.475,0,0,0,.4,1.262,1.6,1.6,0,0,0,.945.441,2.628,2.628,0,0,0,1.485-.249,1.3,1.3,0,0,0,.6-.774,1.233,1.233,0,0,0,.04-.267A1.593,1.593,0,0,0,177.093,24.262Z" transform="translate(-156 -21.257)"/>
                        <path id="Path_505" data-name="Path 505" fill="currentColor" d="M282.993.256a1.5,1.5,0,0,0-.768-.207,1.867,1.867,0,0,0-2.066.837,1.829,1.829,0,0,0-.242,1.036v.065a1.812,1.812,0,0,0,.487,1.235,1.185,1.185,0,0,0,.834.51,2.158,2.158,0,0,0,1.688-.271,1.865,1.865,0,0,0,.819-1.3c.006-.023.014-.045.021-.068a1.678,1.678,0,0,0-.773-1.832Z" transform="translate(-250.919 0.012)"/>
                        <path id="Path_506" data-name="Path 506" fill="currentColor" d="M386.485,10.469a2.91,2.91,0,0,0-2.637-.705A2.325,2.325,0,0,0,381.965,12a2.2,2.2,0,0,0,1.268,2.36,2.9,2.9,0,0,0,1.761.539,2.445,2.445,0,0,0,2.239-1.806A2.976,2.976,0,0,0,386.485,10.469Z" transform="translate(-342.397 -8.693)"/>
                        <path id="Path_507" data-name="Path 507" fill="currentColor" d="M486.676,43.083a3.056,3.056,0,0,0-2.314-2.149,3.252,3.252,0,0,0-3.349,1.277,2.948,2.948,0,0,0-.532,1.88,3.583,3.583,0,0,0,1,2.028,1.172,1.172,0,0,0,1,.369,3.539,3.539,0,0,0,3.12-.372A2.856,2.856,0,0,0,486.676,43.083Z" transform="translate(-430.707 -36.627)"/>
                        <path id="Path_508" data-name="Path 508" fill="currentColor" d="M575.628,101.744a3.466,3.466,0,0,0-2.693-.254,3.851,3.851,0,0,0-2.166,1.036,2.762,2.762,0,0,0-.44,2.952,3.168,3.168,0,0,0,.247,1.208,3.072,3.072,0,0,0,3.19,1.761,3.6,3.6,0,0,0,3.1-2.373A4.129,4.129,0,0,0,575.628,101.744Z" transform="translate(-511.045 -90.843)"/>
                        <path id="Path_509" data-name="Path 509" fill="currentColor" d="M643.833,179.941a3.718,3.718,0,0,0-.932-1.74,3.285,3.285,0,0,0-1.253-1.058,2.746,2.746,0,0,0-2.809.3l-.021.01a2.3,2.3,0,0,0-.859.932q-.088.142-.162.293a4.772,4.772,0,0,0-.369.532,3.173,3.173,0,0,0-.4,2.749,2.674,2.674,0,0,0,1.953,1.72c.111.029.224.054.338.075a2.33,2.33,0,0,0,1.036.121,3.468,3.468,0,0,0,3.46-2.329A2.8,2.8,0,0,0,643.833,179.941Z" transform="translate(-570.902 -158.563)"/>
                        <path id="Path_510" data-name="Path 510" fill="currentColor" d="M82.678,75.225a1.128,1.128,0,0,0-.207-.284.867.867,0,0,0-.111-.088.97.97,0,0,0-.115-.081,1.374,1.374,0,0,0-.241-.1.952.952,0,0,0-.144-.022.974.974,0,0,0-.144-.017,1.023,1.023,0,0,0-.507.133l-.03.018a1.126,1.126,0,0,0-.488.643.82.82,0,0,0-.035.265,1.44,1.44,0,0,0,.035.266,1.2,1.2,0,0,0,.1.267,1.074,1.074,0,0,0,.37.365.842.842,0,0,0,.244.1.655.655,0,0,0,.156.074.7.7,0,0,0,.358.081.945.945,0,0,0,.66-.273.986.986,0,0,0,.276-.66A1.506,1.506,0,0,0,82.678,75.225Z" transform="translate(-72.302 -66.898)"/>
                        <path id="Path_511" data-name="Path 511" fill="currentColor" d="M1.326,146.213a.58.58,0,0,0-.25-.161.583.583,0,0,0-.3-.062.781.781,0,0,0-.549.227.807.807,0,0,0-.228.546v.1a.8.8,0,0,0,.228.549.58.58,0,0,0,.25.161.584.584,0,0,0,.3.071.782.782,0,0,0,.549-.228.8.8,0,0,0,.228-.549v-.1a.8.8,0,0,0-.228-.554Z" transform="translate(0 -130.867)"/>
                        <path id="Path_512" data-name="Path 512" fill="currentColor" d="M134.49,180.188a.593.593,0,0,0-.161-.251.783.783,0,0,0-.55-.227.81.81,0,0,0-.549.227l-.11.114a.8.8,0,0,0-.227.549.583.583,0,0,0,.066.3.593.593,0,0,0,.161.251.783.783,0,0,0,.55.227.81.81,0,0,0,.549-.227l.11-.11a.8.8,0,0,0,.227-.549A.581.581,0,0,0,134.49,180.188Z" transform="translate(-119.128 -161.094)"/>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <p>{{location.description}}</p>
              <h6 :style="{ color: location.color }">Address</h6>
              <div class="office-address">{{location.address}}</div>
              <h6 :style="{ color: location.color }">Contact details</h6>
              <div class="office-details">
                <div>TEL: {{location.phone}}</div>
                <div v-if="location.fax">FAX: {{location.fax}}</div>
                <div>EMAIL: {{location.email}}</div>
              </div>
              <div class="office-map" v-if="location.gmap">
                <Icon size="small" icon="map-marker-alt" type="fas" />
                Find your way to {{location.name}} on <a :href="location.gmap" target="_blank" rel="noopener noreferrer">Google Maps</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "./Icon.vue";
import ContactForm from "./ContactForm.vue";

export default {
  name: "Contact",
  components: {
    Icon,
    ContactForm,
  },
  computed: {
    ...mapGetters({
      settings: "getSettings",
      isMobile: "getMobile",
      testMode: "getTest",
    }),
    db() {
      return this.$store.state.firebase.firestore;
    },
  },
  data() {
    return {
      locations: null,
      settings: null,
    };
  },
  methods: {
    scrollOffices(e) {
      if (window.location.hash.includes('offices')) {
        if (e) {
          e.preventDefault();
        }
        this.$nextTick(() => {
          this.$refs.offices.scrollIntoView();
        });
        return false;
      }
    },
    getItems() {
      if (this.locationsRef) this.locationsRef();
      this.locationsRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}offices`)
        .orderBy("order")
        .onSnapshot((offices) => {
          this.locations = offices.docs.map((doc) => doc.data());
          this.scrollOffices();
        });
      this.settingsRef = this.db()
        .doc(`${this.testMode ? "test_" : ""}settings/contact`)
        .onSnapshot((settings) => {
          this.settings = settings.data();
        });
    },
  },
  mounted: function () {
    this.getItems();
    window.addEventListener('hashchange', this.scrollOffices);
  },
  beforeDestroy: function () {
    window.removeEventListener('hashchange', this.scrollOffices);
    if (this.locationsRef) this.locationsRef();
    if (this.settingsRef) this.settingsRef();
  },
};
</script>

<style scoped>
.offices {
  position: relative;
  margin-top: 268px;
}

.offices:before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  margin: 0 auto 48px;
  width: 7px;
  height: 172px;
  background: url(../assets/images/wb_o_spacer.svg) no-repeat center/contain;
}

.offices h3 {
  color: #1DACD6;
  text-align: center;
  margin-bottom: 24px;
}

.offices h5 {
  color: #656662;
  text-align: center;
}

.offices .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 64px;
}

.offices .office {
  position: relative;
  width: calc(50% - 12px);
  margin-bottom: 64px;
  box-shadow: 0 16px 24px rgba(52, 52, 52, 0.08);
  border-radius: 16px;
  overflow: hidden;
}

.offices .office img {
  display: block;
  width: 100%;
  height: 128px;
  object-fit: cover;
}

.offices .office .office-info {
  padding: 32px 64px 96px;
}

.offices .office .office-name {
  display: flex;
  justify-content: center;
  align-items: center;
  font: 900 22px/1 'Metropolis';
  margin-bottom: 24px;
}

.offices .office p {
  position: relative;
  height: 152px;
  overflow: hidden;
}

.offices .office .office-address {
  white-space: pre-wrap;
}

.offices .office .office-name svg {
  height: 4em;
  transform: rotateZ(180deg);
  margin-left: -1.5em;
}

.offices .office h6 {
  margin: 40px 0 12px;
}

.offices .office .office-map {
  margin-top: 24px;
}

.offices .office .office-map .icon.small {
  width: auto;
  margin-right: 8px;
}

.offices .office .office-map a {
  color: #1DACD6;
  text-decoration: underline;
}

@media screen and (max-width: 1520px), screen and (max-height: 820px) {
  .offices .office .office-info {
    padding: 32px;
  }
  .offices .office p {
    height: auto;
  }
}

@media screen and (max-width: 920px) {
  .offices .container {
    justify-content: center;
  }
  .offices .office {
    width: 100%;
    max-width: 640px;
    margin-bottom: 24px;
  }
}
</style>
